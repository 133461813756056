<template>
  <div v-if="page" v-html="page.content" class="dynamic-page">{{ page }}</div>
  <div v-else>
    <VSkeletonLoader type="heading@1" height="40"/>
    <VSkeletonLoader type="sentences, paragraph@2"/>
  </div>
</template>

<script>
export default {
  name: 'PageViewDetails',
  props: {
    page: {
      type: Object,
    },
  },
};
</script>

<style>
.dynamic-page * {
  max-width: 100%;
}
</style>
